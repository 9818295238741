import { NextPageContext } from 'next';
import { ServerResponse } from 'http';
import { StatusCodes } from 'http-status-codes';
import { ContentRoute, Route } from '@src/interfaces';
import fetchJson from '@src/utils/api/fetch';

const createErrorRoute = ({ status, text }: {
  status: number,
  text: string,
}) => {
  const errorRoute: ContentRoute = {
    type: 'content',
    status,
    title: `${text} | PULS 24`,
    layout: 'plain',
    meta: {
      coralContext: {},
      jsonLD: [],
      linkTags: [],
      metaTags: [],
      somtag: {
        enabled: false,
        slots: {
          desktop: [],
          mobile: [],
        },
        taxonomy: ['other', 'other'],
      },
      subtitle: text,
    },
  };
  return errorRoute;
};

const fetchRoute = async (
  asPath: NextPageContext['asPath'],
  res?: ServerResponse,
): Promise<Route> => {
  if (!asPath) {
    throw new Error('No appContext.ctx.asPath');
  }
  const url = new URL(asPath, 'http://localhost');
  const alias = url.pathname.replace(/\/$/, '');
  return fetchJson<Route>({
    url: `/api/headless/p7s1p4/router?alias=/puls24at${alias}`,
  }).then(fetched => {
    let route: Route;
    if (fetched.ok || [404, 301, 302, 303, 307].includes(fetched.status)) {
      route = fetched.body;
    } else {
      console.error('fetchRoute', asPath, fetched.status);
      route = createErrorRoute({ status: fetched.status, text: 'Sorry!' });
    }
    if (res) {
      const cache = fetched.headers.get('cache-control');
      if (cache) {
        res.setHeader('cache-control', cache);
      }
    }
    return route;
  }).catch(err => {
    console.error('fetchRoute', asPath, err);
    const errorRoute = createErrorRoute({
      status: StatusCodes.INTERNAL_SERVER_ERROR,
      text: 'Ooops!',
    });
    if (res) {
      res.setHeader('cache-control', 'max-age=0, s-maxage=60');
      res.setHeader('x-robots-tag', 'noindex');
    }
    return errorRoute;
  });
};

export default fetchRoute;
