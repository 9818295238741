import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import getCmp, { Language, Modifier, Offset, Vendor, View } from '@src/utils/cmp';
import useHasBeenActive from '@src/utils/hooks/useHasBeenActive';

const pathNeedsBanner = (asPath: string) => asPath !== '/impressum' && asPath !== '/datenschutz';

export type CmpBannerProps = {
  modifiers?: Modifier[];
  view?: View;
  settingsId?: string;
  headline?: string;
  privacyPolicyUrl?: string | Record<Language, string>;
  controllerId?: string;
  visible?: boolean;
  hiddenBackdrop?: boolean;
  initiallyHidden?: boolean;
  hasUserInteracted?: boolean;
  isExpanded?: boolean;
  showExpandButton?: boolean;
  blurred?: boolean;
  offset?: Offset;
  language?: Language;
  onInitialize: (vendors: Vendor[]) => unknown;
};

const CmpBanner = ({ onInitialize, ...props }: CmpBannerProps) => {
  const { asPath } = useRouter();
  const userInteracted = useHasBeenActive();

  const initTracking = useCallback(() => onInitialize(window.cmp?.getVendors() ?? []), [
    onInitialize,
  ]);

  const [userActionRequired, setUserActionRequired] = useState(false);

  useEffect(() => {
    document.addEventListener('cmp:userActionRequired', () => {
      setUserActionRequired(true);
    });
    document.addEventListener('cmp:saved', initTracking);
    document.addEventListener('cmp:confirmed', initTracking);
    document.addEventListener('cmp:userActionNotRequired', initTracking);
    document.addEventListener('cmp:failed', initTracking);

    return () => {
      document.removeEventListener('cmp:userActionRequired', initTracking);
      document.removeEventListener('cmp:saved', initTracking);
      document.removeEventListener('cmp:confirmed', initTracking);
      document.removeEventListener('cmp:userActionNotRequired', initTracking);
      document.removeEventListener('cmp:failed', initTracking);
    };
  }, [initTracking]);

  useEffect(() => {
    if (userInteracted && userActionRequired && pathNeedsBanner(asPath)) {
      getCmp().then(cmp => {
        cmp?.showBanner();
        setUserActionRequired(false);
      });
    }
  }, [asPath, userActionRequired, userInteracted]);

  useEffect(() => {
    getCmp().then(
      cmp => cmp?.hasFailed() && console.warn('CMP failed for controller ID', cmp.getControllerId()),
    );
  }, []);

  return React.createElement('cmp-banner', props);
};

export default CmpBanner;
